import React, { useState, useEffect } from 'react'
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import { Link, useHistory, useParams } from "react-router-dom";
import resources from "../../services";
import Button from "../../ui/Button";
import Icon from '../../ui/Icon';
import Select from 'react-select'

export default function EditUser() {
    const [loader, setLoader] = useState(false),
        history = useHistory(),
        [data, setData] = useState(),
        { id } = useParams(),
        [compganies, setCompganies] = useState([]);


    async function onGet() {
        let res = await resources.storage.readOne(id)
        if (res.status === 200) {
            setData(res.data)
        }
        let com = await resources.vitrine.read('all')
        if (com.status === 200) {
            let a = []
            com.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setCompganies(a)
        }
    }

    useEffect(() => {
        onGet();
    }, [])

    /**
     * @param {React.FormEvent<HTMLFormElement>} e
     */
    async function onCreate(e) {
        e.preventDefault()
        let formData = new FormData(e.target)
        setLoader(true)
        let object = {};
        formData.forEach((value, key) => object[key] = value);
        let res = await resources.storage.update(id, object)
        if (res.status === 201) {
            history.goBack()
        } else {
        }
        setLoader(false)
    }

    return (<div>
        <Header />
        <div className="container-fluid">
            <div className="row">
                <Sidebar />
                <form onSubmit={onCreate}>


                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                        <div>
                            <h3>Update storage location</h3>
                            <div className="d-flex justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        {/* <li className="breadcrumb-item"><Link className="text-muted"
                                            to="/dashboard">Accueil</Link></li> */}
                                        <li className="breadcrumb-item active" aria-current="page"><Link
                                            className="text-muted" to="/setting/storages">Storage location</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Storage location {id}</li>
                                    </ol>
                                </nav>
                                <Button className="btn btn-app-default btn-sm"
                                    type="submit" onLoad={loader}><Icon name="save" color="white" /> save</Button>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-3 mb-3">
                            <div className="col-lg-6 ">
                                <div className="card">
                                    <div className="card-body">



                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Full name <span
                                                style={{ color: "red" }}>*</span></label>
                                            <input type="text" required className="form-control" id="exampleInputEmail1"
                                                name="fullName" defaultValue={data?.fullName} />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Phone number <span
                                                style={{ color: "red" }}>*</span></label>
                                            <input type="text" defaultValue={data?.phone} required className="form-control" id="exampleInputEmail1"
                                                name="phone" />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Email <span
                                                style={{ color: "red" }}>*</span></label>
                                            <input type="text" required className="form-control" id="exampleInputEmail1"
                                                name="email" defaultValue={data?.email} />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1"
                                                className="form-label">Compagny</label>

                                            {
                                                data?.compagny ? <Select name="compagny" options={compganies} defaultValue={
                                                    { label: data?.compagny?.name, value: data?.compagny?.id }
                                                } /> : <Select name="compagny" options={compganies} />
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </form>
            </div>
        </div>

    </div>)
}


