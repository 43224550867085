import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import Sidebar from '../../components/Sidebar'
import { Link, useLocation, useHistory } from "react-router-dom";
import DataTable from "../../components/DataTable";
import resources from "../../services";
import Icon from '../../ui/Icon';
import Select from 'react-select'
import Button from "../../ui/Button";
import config from '../../constants';

function ArticlePage() {

    const [categories, setCategories] = useState([]),
        history = useHistory(),
        [filter, setFilter] = useState(false),
        [compganies, setCompganies] = useState([]);



    async function onGet() {

        let cus = await resources.category.read(50)
        if (cus.status === 200) {
            let a = []
            cus.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setCategories(a)
        }


        let com = await resources.vitrine.read('all')
        if (com.status === 200) {
            let a = []
            com.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setCompganies(a)
        }


    }

    useEffect(() => {
        onGet();
    }, [])

    function filerArticle(e) {
        e.preventDefault();
        setFilter(true)
        let formData = new FormData(e.target);
        let el = '';
        if (formData.get('name')) {
            el = "&name=" + formData.get('name')
        }
        if (formData.get('id')) {
            el += "&id=" + formData.get('id')
        }

        if (formData.get('compagny')) {
            el += "&compagny=" + formData.get('compagny')
        }
        if (formData.get('categorie')) {
            el += "&category=" + formData.get('categorie')
        }
        if (formData.get('quantity')) {
            el += "&quantity=" + formData.get('quantity')
        }

        history.push("/articles?" + el)

    }

    function onChangeFilterLoader() {
        setFilter(false)
    }

    async function onUpdate(article, callback) {

        let data = {}
        if (article.is_active) {
            data.is_active = 0;
        } else {
            data.is_active = true;

        }
        let res = await resources.article.update(article.id, data)
        if (res.status === 200) {
            callback()
        }
    }



    return (<div>
        <Header />
        <div className="container-fluid">
            <div className="row">
                <Sidebar />
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                    <div>
                        <h3>Articles</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    {/* <li className="breadcrumb-item "><Link className="text-muted"
                                        to="/dashboard">Acceuil</Link></li> */}
                                    <li className="breadcrumb-item active" aria-current="page">Articles</li>
                                </ol>
                            </nav>
                            <Link className="btn btn-app-default " to="/article/create"><Icon name="add" color="white" /> New</Link>
                        </div>
                    </div>

                    <div className="col mt-5">
                        <div className="card card-body mb-3">
                            <form onSubmit={filerArticle}>
                                <div className="row">
                                    <div className="col">
                                        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="ID" name="id" />
                                    </div>
                                    <div className="col">
                                        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Name" name="name" />
                                    </div>

                                    <div className="col">
                                        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Stock" name="price" />
                                    </div>
                                    <div className="col">
                                        <Select name="compagny" placeholder="Compagny" options={compganies} />
                                    </div>
                                    <div className="col">
                                        <Select name="categorie" placeholder="Categorie" options={categories} />
                                    </div>

                                    <div className="col">
                                        <Button onLoad={filter} className="btn btn-app-default w-100" type="submit"> Apply</Button>
                                    </div>

                                </div>

                            </form>

                        </div>
                        <DataTable column={[
                            {
                                label: "Cover ", field: "picture", renderStyle: (data) => {
                                    return <img src={config.server.localhost + data.pictureUrl} alt={data.name} width="50" />
                                }
                            },
                            { label: "Material number", field: "id" },
                            { label: "Material Name", field: "name" },
                            { label: "Company", field: "compagny", render: ["name"] },
                            { label: "Stock on hand", field: "price" },
                            { label: "Material type", field: "category", render: ["name"] },
                            { label: "Material sub-type", field: "subtype", render: ["name"] },
                            { label: "Storage location", field: "location", render: ["name"] },
                            { label: "Bin location", field: "bin", render: ["name"] },
                            { label: "Where used", field: "whereUsed" },
                            { label: "Set size", field: "size" },
                            { label: "OEM", field: "oem" },
                            { label: "Last purchased date", field: "lastFrom" },
                            { label: "Last purchased from", field: "from" },
                            { label: "Material part number", field: "matNumber" },
                        ]}


                            filterLoader={onChangeFilterLoader}
                            element={"article"}
                            resource={resources.article}
                            action={(data) => {

                            }}
                            view
                            update
                        />
                    </div>
                </main>
            </div>
        </div>

    </div>)
}

export default ArticlePage
