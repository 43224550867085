import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import config from "../constants";
import logo from "../assets/logo.png"
import Icon from '../ui/Icon';

function Header() {

    const logout = () => {
        localStorage.clear()
        window.location.replace("/");
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-white sticky-top border-bottom">
            <div className="container">
                <Link className="navbar-brand" onClick={() => window.location.reload()}><img src={logo} width="50" alt={config.app.name} /> </Link>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item dropstart">
                            <a className="nav-link " href="/dashboard" id="navbarDropdown" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <Icon className="bi d-block mx-auto mb-1" name="people-circle" />
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><Link className="dropdown-item" to="/settings">Réglages</Link></li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li><a className="dropdown-item" onClick={logout}>Déconnexion</a></li>
                            </ul>
                        </li>

                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Header
