import React, { useState } from "react"
import Button from "../../ui/Button";
import resources from "../../services";
import config from "../../constants";
import logo from "../../assets/logo.png"

export default function LoginPage(key) {
    const [loader, setLoader] = useState(false), [error, setError] = useState(false)

    async function auth(e) {
        e.preventDefault()
        setLoader(true)
        let fromData = new FormData(e.target)
        let res = await resources.auth.auth(fromData)
        if (res.status === 200) {
            if (res.data.result) {
                let a = res.data.value

                if (a.compagny) {

                    alert("Access denied")

                } else {
                    localStorage.setItem("userId", a.id.toString());
                    localStorage.setItem("userName", a.fullName);
                    setLoader(false)
                    window.location.replace("/articles")
                }


            } else {
                setLoader(false)
                setError(true)

            }
        }
        setLoader(false)

    }

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-lg-4">
                    <div className="mb-5">
                        <h1 className="fw-bold text-center color-app-default">
                            <img src={logo} width="180" alt={config.app.name} />

                        </h1>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={auth}>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Email</label>
                                    <input type="text" className="form-control" id="exampleInputEmail1"
                                        name="email" required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                    <input type="password" required className="form-control" id="exampleInputPassword1"
                                        name="password" />
                                </div>
                                <Button type="submit" onLoad={loader} className="btn btn-app-default w-100">Login</Button>
                            </form>

                        </div>
                    </div>
                    {
                        error && (<div className="alert alert-danger alert-dismissible fade show mt-3">
                            <strong>Email or password wrong</strong>
                            <button type="button" className="btn-close" onClick={() => setError(false)}></button>
                        </div>)
                    }

                </div>
            </div>

        </div>
    )
}