import React, { useState, useEffect } from 'react'
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import config from '../../constants';
import { Link, useHistory, useParams } from "react-router-dom";
import resources from "../../services";
import Button from "../../ui/Button";
import Select from 'react-select'
import Icon from '../../ui/Icon';

function EditArticle() {

    const [loader, setLoader] = useState(false),
        history = useHistory(),
        [categories, setCategories] = useState([]),
        [tags, setTags] = useState([]),
        [vitrines, setVitrines] = useState([]),
        [category, setCategory] = useState(),
        [tag, setTag] = useState(),
        [vitrine, setVitrine] = useState(),
        { id } = useParams(),
        [data, setData] = useState({}),
        [display, setDisplay] = useState(0),
        [quantity, setQuantity] = useState(0),
        [loading, setLoading] = useState(false),
        [storages, setStorages] = useState([]),
        [bins, setBins] = useState([]),
        [compganies, setCompganies] = useState([]);

    async function onGet() {

        let com = await resources.vitrine.read('all')
        if (com.status === 200) {
            let a = []
            com.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setCompganies(a)
        }

        let tag = await resources.tag.read(50)
        if (tag.status === 200) {
            let a = []
            tag.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setTags(a)
        }

        let res = await resources.article.readOne(id)
        if (res.status === 200) setData(res.data)

        let cus = await resources.category.read(50)
        if (cus.status === 200) {
            let a = []
            cus.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setCategories(a)
        }

        let sto = await resources.storage.read(50)
        if (sto.status === 200) {
            let a = []
            sto.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setStorages(a)
        }

        let bi = await resources.bin.read(50)
        if (bi.status === 200) {
            let a = []
            bi.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setBins(a)
        }

    }

    useEffect(() => {
        onGet();
    }, [id])

    /**
     * @param {React.FormEvent<HTMLFormElement>} e
     */
    async function onUpdate(e) {
        e.preventDefault()
        setLoader(true)

        let formData = new FormData(e.target)
        if (vitrine) {
            formData.append("vitrine", vitrine)
        }

        if (category) {
            formData.append("type", category)
        }


        if (tag) {
            let a = []
            a.push(tag)
            formData.append("tags", JSON.stringify(a))
        }

        let object = {};
        formData.forEach((value, key) => object[key] = value);
        let res = await resources.article.update(id, object)
        if (res.status === 201) {
            history.goBack()
        } else {
        }
        setLoader(false)
    }

    async function addStock(e) {
        e.preventDefault();
        setLoading(true)
        let formData = new FormData();
        formData.append("sourceFile", quantity);
        formData.append("product", id);

        let res = await resources.article.addImage(formData);
        if (res) {
            onGet()
            setLoading(false)
        }
        setLoading(false)
    }

    return (<div>
        <Header />
        <div className="container-fluid">
            <div className="row">
                <Sidebar />
                <form onSubmit={onUpdate}>
                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                        <div>
                            <h3>update article </h3>
                            <div className="d-flex justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        {/* <li className="breadcrumb-item"><Link className="text-muted"
                                            to="/dashboard">Acceuil</Link></li> */}
                                        <li className="breadcrumb-item active" aria-current="page"><Link
                                            className="text-muted" to="/articles">Articles</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Article {id}</li>
                                    </ol>
                                </nav>
                                {
                                    display === 0 && <Button className="btn btn-app-default btn-sm"
                                        type="submit" onLoad={loader}><Icon name="save" color="white" /> Save</Button>
                                }
                                {
                                    display === 1 && <button className="btn btn-app-default btn-sm"
                                        type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" ><Icon name="stock" color="white" /> Add image</button>
                                }

                            </div>
                        </div>
                        <div className="row justify-content-center mt-3 mb-3">

                            <ul class="nav nav-tabs" id="myTab" role="tablist">

                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" onClick={() => setDisplay(0)}>Informations</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false" onClick={() => setDisplay(1)}>Image</button>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">

                                <div class="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    <div className="col-lg-6 mt-3">
                                        <div className="card">



                                            <div className="card-body">


                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1" className="form-label">Material name <span
                                                        style={{ color: "red" }}>*</span></label>
                                                    <input type="text" required className="form-control" id="exampleInputEmail1"
                                                        name="name" defaultValue={data?.name} />
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1" className="form-label">Materail part number<span
                                                        style={{ color: "red" }}>*</span></label>
                                                    <input type="text" required className="form-control" id="exampleInputEmail1"
                                                        name="matNumber" defaultValue={data?.matNumber} />
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1"
                                                        className="form-label">Company</label>

                                                    {
                                                        data?.compagny ? <Select name="compagny" options={compganies} defaultValue={
                                                            { label: data?.compagny?.name, value: data?.compagny?.id }
                                                        } /> : <Select name="compagny" options={compganies} />
                                                    }
                                                </div>


                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1"
                                                        className="form-label">Material type <span
                                                            style={{ color: "red" }}>*</span></label>

                                                    {
                                                        data?.category ? <Select onChange={(e) => setCategory(e.value)} options={categories} defaultValue={
                                                            { label: data?.category?.name, value: data?.category?.id }
                                                        } /> : <Select name="category" options={categories} />
                                                    }
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1"
                                                        className="form-label">Material sub-type <span
                                                            style={{ color: "red" }}>*</span></label>

                                                    {
                                                        data?.subtype ? <Select name="subtype" options={tags} defaultValue={
                                                            { label: data?.subtype?.name, value: data?.subtype?.id }
                                                        } /> : <Select name="subtype" options={tags} />
                                                    }
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1"
                                                        className="form-label">Storage location<span
                                                            style={{ color: "red" }}>*</span></label>

                                                    {
                                                        data?.location ? <Select name="location" options={storages} defaultValue={
                                                            { label: data?.location?.name, value: data?.location?.id }
                                                        } /> : <Select name="bin" options={storages} />
                                                    }
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1"
                                                        className="form-label">Bin location<span
                                                            style={{ color: "red" }}>*</span></label>
                                                    {
                                                        data?.bin ? <Select name="bin" options={bins} defaultValue={
                                                            { label: data?.bin?.name, value: data?.bin?.id }
                                                        } /> : <Select name="bin" options={bins} />
                                                    }
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1" className="form-label">Stock on hand <span
                                                        style={{ color: "red" }}>*</span></label>
                                                    <input type="number" required className="form-control" id="exampleInputEmail1"
                                                        name="price" defaultValue={data?.price} />
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1" className="form-label">Last purchased date <span
                                                        style={{ color: "red" }}>*</span></label>
                                                    <input type="date" className="form-control" id="exampleInputEmail1"
                                                        name="lastFrom" />
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1" className="form-label">Last purchased From <span
                                                        style={{ color: "red" }}>*</span></label>
                                                    <input type="date" className="form-control" id="exampleInputEmail1"
                                                        name="from" defaultValue={data?.from} />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1" className="form-label">Set size<span
                                                        style={{ color: "red" }}>*</span></label>
                                                    <input type="text" required className="form-control" id="exampleInputEmail1"
                                                        name="size" defaultValue={data?.size} />
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1" className="form-label">OEM<span
                                                        style={{ color: "red" }}>*</span></label>
                                                    <input type="text" required className="form-control" id="exampleInputEmail1"
                                                        name="oem" defaultValue={data?.oem} />
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1" className="form-label">Where used<span
                                                        style={{ color: "red" }}>*</span></label>
                                                    <input type="text" required className="form-control" id="exampleInputEmail1"
                                                        name="whereUsed" defaultValue={data?.whereUsed} />
                                                </div>


                                                <div className="mb-3">
                                                    <label htmlFor="exampleFormControlTextarea1"
                                                        className="form-label">Description <span
                                                            style={{ color: "red" }}>*</span></label>
                                                    <textarea className="form-control" id="exampleFormControlTextarea1"
                                                        rows="3" name="note" defaultValue={data?.note} />
                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">

                                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog">


                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="exampleModalLabel">Add image</h5>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <div className="mb-3">
                                                        <label htmlFor="exampleInputEmail1" className="form-label">Image<span
                                                            style={{ color: "red" }}>*</span></label>
                                                        <input type="file" className="form-control" id="exampleInputEmail1"
                                                            name="sourceFile"
                                                            onChange={(e) => setQuantity(e.target.files[0])} />
                                                    </div>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">Cancel</button>
                                                    <Button type="button" class="btn btn-primary btn-sm" onLoad={loading} onClick={addStock}>Save</Button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="row">
                                        {
                                            data?.productImages && data.productImages.map((el, key) => {
                                                return <div className="col-lg-4"><img key={key} src={config.server.localhost + "/uploads/products/" + el.source} width="280" alt={data?.name} className="mb-2" /></div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>


                        </div>
                    </main>
                </form>
            </div>
        </div>

    </div>)
}

export default EditArticle
