import Axios from "../Axios";

const article = {
    create(data) {
        return Axios.post('/product', data)
    },
    read(limit = 10, params = '', page = 1) {
        return Axios.get('/product?limit=' + limit + "&page=" + page + params)
    },
    online(limit = 10, page = 1) {
        return Axios.get('/product?is_active=1&limit=' + limit + "&page=" + page)
    },
    readOne(id) {
        return Axios.get('/product/' + id)
    },
    update(id, data) {
        return Axios.put('/product/' + id, data)
    },
    delete(id) {
        return Axios.put('/product/' + id, {
            "active": false
        })
    },
    readImage(id) {
        return Axios.get("/image?product=" + id);
    },
    addImage(data) {
        return Axios.post("/image", data);
    },
    deleteImage(data) {
        return Axios.delete("/image", data);
    },




}

export default article;
