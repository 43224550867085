import React, { useState, useEffect, useRef } from 'react'
import Header from '../../components/Header'
import Sidebar from '../../components/Sidebar'
import { Link, useLocation, useHistory } from "react-router-dom";
import DataTable from "../../components/DataTable";
import DataReport from "../../components/DataReport";
import resources from "../../services";
import Icon from '../../ui/Icon';
import Select from 'react-select'
import Button from "../../ui/Button";
import config from '../../constants';
import moment from "moment"
import { ComponentToPrint } from './ComponentToPrint';
import { useReactToPrint } from 'react-to-print';
import Detail from '../../components/Detail';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';




export default function Index() {

    const [categories, setCategories] = useState([]),
        [data, setData] = useState([]),
        [filter, setFilter] = useState(false),
        [compagnies, setCompagnies] = useState([]);

    function format_date(value) {
        return moment(value).format("DD-MM-YYYY");
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    const componentRef1 = useRef();
    const handlePrint1 = useReactToPrint({
        content: () => componentRef1.current,
    });


    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData = [], fileName) => {

        let a = []
        csvData.forEach((el) => {
            a.push({
                MaterialNumber: el.id,
                Cover: config.server.localhost + "/uploads/products/" + el.picture,
                MaterialName: el.name,
                Company: el.compagny?.name,
                StockOnHand: el.price,
                MaterialType: el.category?.name,
                StorageLocation: el.subtype?.name,
                BinLocation: el.bin?.name,
                WhereUsed: el.whereUsed,
                SetSize: el.size,
                OEM: el.oem,
                LastPurchasedDate: el.lastFrom,
                LastPurchasedFrom: el?.from,
                MaterialNumber: el.matNumber

            })
        })

        const ws = XLSX.utils.json_to_sheet(a);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }


    async function onGet() {

        let cus = await resources.category.read('all')
        if (cus.status === 200) {
            let a = []
            cus.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setCategories(a)
        }

        let com = await resources.vitrine.read('all')
        if (com.status === 200) {
            let a = []
            com.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setCompagnies(a)
        }


    }

    useEffect(() => {
        onGet();
    }, [])

    async function filerArticle(e) {
        e.preventDefault();
        setFilter(true)

        let formData = new FormData();
        if (e.target.from.value) {
            formData.append("from", format_date(e.target.from.value));
        }
        if (e.target.to.value) {
            formData.append("to", format_date(e.target.to.value));
        }

        if (e.target.compagny.value) {
            formData.append("compagny", e.target.compagny.value);
        }

        if (e.target.category.value) {
            formData.append("category", e.target.category.value);
        }

        let res = await resources.report.read(formData)
        if (res.status === 200) {
            setData(res.data)
            setFilter(false)

        }
        setFilter(false)

    }



    return (<div>
        <Header />
        <div className="container-fluid">
            <div className="row">
                <Sidebar />
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                    <div>
                        <h3>Report</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    {/* <li className="breadcrumb-item "><Link className="text-muted"
                                        to="/dashboard">Acceuil</Link></li> */}
                                    <li className="breadcrumb-item active" aria-current="page">Articles</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="col mt-5">
                        <div className="card card-body mb-3">
                            <form onSubmit={filerArticle}>
                                <div className="row">
                                    <div className="col">
                                        <input type="date" class="form-control" id="exampleFormControlInput1" placeholder="From" name="from" />
                                    </div>
                                    <div className="col">
                                        <input type="date" class="form-control" id="exampleFormControlInput1" placeholder="To" name="to" />
                                    </div>
                                    <div className="col">
                                        <Select name="compagny" placeholder="Compagny" options={compagnies} />
                                    </div>
                                    <div className="col">
                                        <Select name="category" placeholder="Categorie" options={categories} />
                                    </div>
                                    <div className="col">
                                        <Button onLoad={filter} className="btn btn-app-default w-100" type="submit"> Apply</Button>
                                    </div>

                                    <div className="col">
                                        <div class="dropdown">
                                            <button class="btn btn-app-default w-100 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Print options
                                            </button>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item" href="#" onClick={handlePrint}>All list</a></li>
                                                <li><a class="dropdown-item" href="#" onClick={handlePrint1}>Detailed list</a></li>
                                                <li><a class="dropdown-item" href="#" onClick={() => exportToCSV(data, "report")}>Save to excel format</a></li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>

                            </form>

                        </div>
                        <ComponentToPrint ref={componentRef} >


                            <DataReport column={[
                                {
                                    label: "Cover ", field: "picture", renderStyle: (data) => {
                                        return <img src={config.server.localhost + "/uploads/products/" + data.picture} alt={data.name} width="50" />
                                    }
                                },
                                { label: "Material number ", field: "id" },
                                { label: "Material Name", field: "name" },
                                { label: "Company", field: "compagny", render: ["name"] },
                                { label: "Stock on hand", field: "price" },
                                { label: "Material type", field: "category", render: ["name"] },
                                { label: "Material sub-type", field: "subtype", render: ["name"] },
                                { label: "Storage location", field: "location", render: ["name"] },
                                { label: "Bin location", field: "bin", render: ["name"] },
                                { label: "Where used", field: "whereUsed" },
                                { label: "Set size", field: "size" },
                                { label: "OEM", field: "oem" },
                                { label: "Last purchased date", field: "lastFrom" },
                                { label: "Last purchased from", field: "from" },
                                { label: "Material number", field: "matNumber" },
                            ]}
                                row={data}
                            />

                        </ComponentToPrint>

                        <div style={{ display: 'none' }}>
                            <ComponentToPrint ref={componentRef1} >
                                {data && data.map((el, key) => {
                                    return <Detail data={el} />
                                })}

                            </ComponentToPrint>
                        </div>

                    </div>
                </main>
            </div>
        </div>

    </div>)
}


