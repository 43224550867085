import React from 'react'
import QRCode from "react-qr-code";
import logo from "../assets/logo.png"
import config from '../constants';

function Detail({ data }) {
    return (
        <div>

            <div className="card-header">
                <div className="d-flex justify-content-between">
                    <div>
                        <img src={logo} width="180" alt="PICCO" />
                    </div>
                    <div>
                        <QRCode
                            size={120}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={data?.id}
                        />
                    </div>

                </div>

            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col">
                        <div className="row">
                            <div className="col-lg-4">
                                <img src={config.server.localhost + "/uploads/products/" + data.picture} width="180" alt={data?.name} className="mb-2" />
                                {
                                    data?.productImages && data.productImages.map((el, key) => {
                                        return <img key={key} src={config.server.localhost + "/uploads/products/" + el.source} width="180" alt={data?.name} className="mb-2" />
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div class="ms-2 me-auto mb-1">
                            <div class="fw-bold">Material number</div>
                            {data?.id}
                        </div>

                        <div class="ms-2 me-auto mb-1">
                            <div class="fw-bold">Material name</div>
                            {data?.name}
                        </div>
                        <div class="ms-2 me-auto mb-1">
                            <div class="fw-bold">Stock on hand</div>
                            {data?.subtype?.name}
                        </div>

                        <div class="ms-2 me-auto mb-1">
                            <div class="fw-bold">Material type</div>
                            {data?.category?.name}
                        </div>

                        <div class="ms-2 me-auto mb-1">
                            <div class="fw-bold">Material sub-type</div>
                            {data?.subtype?.name}
                        </div>


                        <div class="ms-2 me-auto mb-1">
                            <div class="fw-bold">Storage location</div>
                            {data?.location?.name}
                        </div>

                        <div class="ms-2 me-auto mb-1">
                            <div class="fw-bold">Bin location</div>
                            {data?.bin?.name}
                        </div>

                        <div class="ms-2 me-auto mb-1">
                            <div class="fw-bold">Company</div>
                            {data?.compagny?.name}
                        </div>

                        <div class="ms-2 me-auto mb-1">
                            <div class="fw-bold">Stock on hand</div>
                            {data?.price}
                        </div>
                        <div class="ms-2 me-auto mb-1">
                            <div class="fw-bold">Description</div>
                            {data?.note}
                        </div>
                        <div class="ms-2 me-auto mb-1">
                            <div class="fw-bold">OEM</div>
                            {data?.oem}
                        </div>
                        <div class="ms-2 me-auto mb-1">
                            <div class="fw-bold">Set size</div>
                            {data?.size}
                        </div>
                        <div class="ms-2 me-auto mb-1">
                            <div class="fw-bold">Last purchased date</div>
                            {data?.lastFrom}
                        </div>

                        <div class="ms-2 me-auto mb-1">
                            <div class="fw-bold">Last purchased date</div>
                            {data?.from}
                        </div>

                        <div class="ms-2 me-auto mb-1">
                            <div class="fw-bold">where used</div>
                            {data?.whereUsed}
                        </div>
                        <div class="ms-2 me-auto mb-1">
                            <div class="fw-bold">Material number</div>
                            {data?.matNumber}
                        </div>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default Detail