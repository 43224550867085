import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from 'react-to-print';
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { ComponentToPrint } from './ComponentToPrint';
import { Link, useParams } from "react-router-dom";
import resources from "../../services";
import QRCode from "react-qr-code";
import logo from "../../assets/logo.png"
import config from '../../constants';

const ArticleViewPage = () => {
    const componentRef = useRef();
    const { id } = useParams();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const [data, setData] = useState({});
    async function onGet() {
        let res = await resources.article.readOne(id)
        if (res.status === 200) setData(res.data)
    }

    useEffect(() => {
        onGet();
    }, [id])

    return (
        <div>
            <Header />
            <div className="container-fluid">
                <div className="row">
                    <Sidebar />
                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                        <div className="mb-5">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <nav className="nav">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item active" aria-current="page"><Link
                                                    className="text-muted" to="/articles">Articles</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page">article { }</li>
                                            </ol>
                                        </nav>

                                    </nav>
                                </div>
                                <div>
                                    <button className="btn btn-app-default w-100 float-right"
                                        onClick={handlePrint}><i className="bi bi-printer mr-1" />Imprimer
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div className="col">
                            <div className="card">
                                <ComponentToPrint ref={componentRef}>

                                    <div className="card-header">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <img src={logo} width="180" alt="PICCO" />
                                            </div>
                                            <div>
                                                <QRCode
                                                    size={120}
                                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                    value={id}
                                                />
                                            </div>

                                        </div>

                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <img src={config.server.localhost + data.pictureUrl} width="180" alt={data?.name} className="mb-2" />
                                                        {
                                                            data?.productImages && data.productImages.map((el, key) => {
                                                                return <img key={key} src={config.server.localhost + "/uploads/products/" + el.source} width="180" alt={data?.name} className="mb-2" />
                                                            })
                                                        }

                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col">

                                                <div class="ms-2 me-auto mb-1">
                                                    <div class="fw-bold">Material number</div>
                                                    {data?.id}
                                                </div>

                                                <div class="ms-2 me-auto mb-1">
                                                    <div class="fw-bold">Material name</div>
                                                    {data?.name}
                                                </div>


                                                <div class="ms-2 me-auto mb-1">
                                                    <div class="fw-bold">Material type</div>
                                                    {data?.category?.name}
                                                </div>

                                                <div class="ms-2 me-auto mb-1">
                                                    <div class="fw-bold">Material sub-type</div>
                                                    {data?.subtype?.name}
                                                </div>


                                                <div class="ms-2 me-auto mb-1">
                                                    <div class="fw-bold">Storage location</div>
                                                    {data?.location?.name}
                                                </div>

                                                <div class="ms-2 me-auto mb-1">
                                                    <div class="fw-bold">Bin location</div>
                                                    {data?.bin?.name}
                                                </div>

                                                <div class="ms-2 me-auto mb-1">
                                                    <div class="fw-bold">Company</div>
                                                    {data?.compagny?.name}
                                                </div>

                                                <div class="ms-2 me-auto mb-1">
                                                    <div class="fw-bold">Stock on hand</div>
                                                    {data?.price}
                                                </div>
                                                <div class="ms-2 me-auto mb-1">
                                                    <div class="fw-bold">Description</div>
                                                    {data?.note}
                                                </div>
                                                <div class="ms-2 me-auto mb-1">
                                                    <div class="fw-bold">OEM</div>
                                                    {data?.oem}
                                                </div>
                                                <div class="ms-2 me-auto mb-1">
                                                    <div class="fw-bold">Set size</div>
                                                    {data?.size}
                                                </div>
                                                <div class="ms-2 me-auto mb-1">
                                                    <div class="fw-bold">Last purchased date</div>
                                                    {data?.lastFrom}
                                                </div>

                                                <div class="ms-2 me-auto mb-1">
                                                    <div class="fw-bold">Last purchased from</div>
                                                    {data?.from}
                                                </div>
                                                <div class="ms-2 me-auto mb-1">
                                                    <div class="fw-bold">where used</div>
                                                    {data?.whereUsed}
                                                </div>
                                                <div class="ms-2 me-auto mb-1">
                                                    <div class="fw-bold">Material number</div>
                                                    {data?.matNumber}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </ComponentToPrint>
                            </div>
                        </div>

                    </main>
                </div>
            </div>
        </div>
    );
};

export default ArticleViewPage;
