import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import Sidebar from '../../components/Sidebar'
import { Link } from "react-router-dom";
import DataTable from "../../components/DataTable";
import resources from "../../services";
import config from '../../constants';
import Icon from '../../ui/Icon';

export default function CustomerPage() {

    async function onUpdate(data, callback) {


        let res = await resources.seller.create({ user: data.id })
        if (res.status === 201) {
            callback()
        }
    }

    return (<div>
        <Header />
        <div className="container-fluid">
            <div className="row">
                <Sidebar />
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                    <div>
                        <h3>Users</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    {/* <li className="breadcrumb-item "><Link className="text-muted"
                                        to="/dashboard">Accueil</Link></li> */}
                                    <li className="breadcrumb-item active" aria-current="page">Users</li>
                                </ol>
                            </nav>
                            <Link className="btn btn-app-default " to="/user/create"><Icon name="add" color="white" /> New</Link>
                        </div>
                    </div>

                    <div className="col mt-5">
                        <DataTable column={[
                            { label: "Full name", field: "fullName" },
                            { label: "Email", field: "email" },
                            { label: "Phone number", field: "phone" },
                            { label: "Compagny", field: "compagny", render: ["name"] },

                        ]}
                            element={"user"}
                            resource={resources.user}
                            action={(data, callback) => {
                            }}
                            deleteItem
                        />
                    </div>
                </main>
            </div>
        </div>

    </div>)
}


