import customer from "./customer"
import tag from "./tag"
import vitrine from "./vitrine"
import article from "./article"
import user from "./user"
import auth from "./auth"
import storage from "./storage"
import command from "./command"
import chart from "./chart"
import category from "./category"
import report from "./report"
import bin from "./bin"



const resources = {
    auth,
    customer,
    article,
    tag,
    user,
    vitrine,
    storage,
    bin,
    command,
    chart,
    category,
    report
};
export default resources;
