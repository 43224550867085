import React, { useState, useEffect } from 'react'
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import { Link, useHistory } from "react-router-dom";
import resources from "../../services";
import Button from "../../ui/Button";
import Select from 'react-select'
import Icon from '../../ui/Icon';

export default function NewArticle() {
    const [loader, setLoader] = useState(false),
        history = useHistory(),
        [categories, setCategories] = useState([]),
        [tags, setTags] = useState([]),
        [category, setCategory] = useState(),
        [tag, setTag] = useState(),
        [storages, setStorages] = useState([]),
        [bins, setBins] = useState([]);


    async function onGet() {

        let cus = await resources.category.read(50)
        if (cus.status === 200) {
            let a = []
            cus.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setCategories(a)
        }

        let tag = await resources.tag.read(50)
        if (tag.status === 200) {
            let a = []
            tag.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setTags(a)
        }

        let sto = await resources.storage.read(50)
        if (sto.status === 200) {
            let a = []
            sto.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setStorages(a)
        }

        let bi = await resources.bin.read(50)
        if (bi.status === 200) {
            let a = []
            bi.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setBins(a)
        }

    }

    useEffect(() => {
        onGet();
    }, [])

    /**
     * @param {React.FormEvent<HTMLFormElement>} e
     */
    async function onCreate(e) {
        e.preventDefault()
        let formData = new FormData(e.target)


        if (category) {
            formData.append("category", category)
        }

        if (tag) {
            let a = []
            a.push(tag)
            formData.append("tag", JSON.stringify(a))
        }
        formData.append("quantity", 0)
        setLoader(true)
        let res = await resources.article.create(formData)
        if (res.status === 201) {
            alert("Success")
        } else {
            alert("Error")
        }
        setLoader(false)
    }

    return (<div>

        <div className="container-fluid">
            <div className="row">

                <form onSubmit={onCreate}>

                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">

                        <div className="row justify-content-center mt-3 mb-3">
                            <div className="col-lg-6 ">
                                <div className="card">
                                    <div className="card-body">

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Image <span
                                                style={{ color: "red" }}>*</span></label>
                                            <input type="file" required className="form-control" id="exampleInputEmail1"
                                                name="pictureFile" />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Material name <span
                                                style={{ color: "red" }}>*</span></label>
                                            <input type="text" required className="form-control" id="exampleInputEmail1"
                                                name="name" />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Material part number<span
                                                style={{ color: "red" }}>*</span></label>
                                            <input type="text" required className="form-control" id="exampleInputEmail1"
                                                name="matNumber" />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1"
                                                className="form-label">Material type <span
                                                    style={{ color: "red" }}>*</span></label>
                                            <Select required onChange={(e) => setCategory(e.value)} options={categories} />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1"
                                                className="form-label">Materail sub-type <span
                                                    style={{ color: "red" }}>*</span></label>
                                            <Select name="subtype" options={tags} />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1"
                                                className="form-label">Storage location<span
                                                    style={{ color: "red" }}>*</span></label>
                                            <Select name="location" options={storages} />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1"
                                                className="form-label">Bin location<span
                                                    style={{ color: "red" }}>*</span></label>
                                            <Select name="bin" options={bins} />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Stock on hand <span
                                                style={{ color: "red" }}>*</span></label>
                                            <input type="number" required className="form-control" id="exampleInputEmail1"
                                                name="price" />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Last purchased date <span
                                                style={{ color: "red" }}>*</span></label>
                                            <input type="date" className="form-control" id="exampleInputEmail1"
                                                name="lastFrom" />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Last purchased From <span
                                                style={{ color: "red" }}>*</span></label>
                                            <input type="date" className="form-control" id="exampleInputEmail1"
                                                name="from" />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Set size<span
                                                style={{ color: "red" }}>*</span></label>
                                            <input type="text" required className="form-control" id="exampleInputEmail1"
                                                name="size" />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">OEM<span
                                                style={{ color: "red" }}>*</span></label>
                                            <input type="text" required className="form-control" id="exampleInputEmail1"
                                                name="oem" />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Where used<span
                                                style={{ color: "red" }}>*</span></label>
                                            <input type="text" required className="form-control" id="exampleInputEmail1"
                                                name="whereUsed" />
                                        </div>


                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlTextarea1"
                                                className="form-label">Description <span
                                                    style={{ color: "red" }}>*</span></label>
                                            <textarea className="form-control" id="exampleFormControlTextarea1"
                                                rows="3" name="note" />
                                        </div>


                                        <div className="mb-3">
                                            <Button className="btn btn-app-default btn-sm w-100"
                                                type="submit" onLoad={loader}><Icon name="save" color="white" /> Save</Button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </form>
            </div>
        </div>

    </div>)
}


