import Axios from "../Axios";

const command = {
    create(data) {
        return Axios.post('/vaccination', data)
    },
    read(limit = 10, page = 1) {
        return Axios.get('/vaccination?&limit=' + limit + "&page=" + page)
    },
    pending(limit = 10, page = 1) {
        return Axios.get('/vaccination?status=1&limit=' + limit + "&page=" + page)
    },
    readOne(id) {
        return Axios.get('/vaccination/' + id)
    },
    update(id, data) {
        return Axios.put('/vaccination/' + id, data);
    },
    updateItem(id, data) {
        return Axios.put("/vaccination/" + id, data);
    },
    deleteItem(id) {
        return Axios.delete("/vaccination/" + id);
    },
    delete(id) {
        return Axios.put('/vaccination/' + id)
    },
}

export default command;
