import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom";
import Icon from "../ui/Icon";



export default function DateReport({ column, row }) {

    let text = window.location.href;
    const myArray = text.split("?");
    const [data, setData] = useState(row),
        [detail, setDetail] = useState({}),
        [open, setOpen] = useState(false),
        [item, setItem] = useState(),
        [limit, setLimit] = useState(10),
        [page, setPage] = useState(1),
        [pages, setPages] = useState([]),
        [loader, setLoader] = useState(true),
        [currentPage, setCurrentPage] = useState(1)



    useEffect(() => {
    }, [limit, page, myArray[1]])

    return (
        <div>

            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            {column && column.map((i, k) => {
                                return <th scope="col" key={k}>{i.label}</th>
                            })}

                        </tr>
                    </thead>
                    <tbody>
                        {
                            row && row.map((el, key) => {
                                return <tr className="bg-white" key={key}>
                                    {column && column.map((i) => {
                                        return <td>{!i.renderStyle ? !i.render ? el.hasOwnProperty(i.field) && el[i.field] : i.render.map((a, key) => {
                                            return el.hasOwnProperty(i.field) && el[i.field] ? el[i.field][a] : null
                                        }) : i.renderStyle(el)}</td>
                                    })}

                                </tr>
                            })
                        }

                    </tbody>
                </table>
            </div>

        </div>
    )
}