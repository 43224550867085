import Axios from "../Axios";

const user = {
    create(data) {
        return Axios.post('/staff', data)
    },
    read(limit = 10, page = 1) {
        return Axios.get('/staff?limit=' + limit + "&page=" + page)
    },
    readOne(id) {
        return Axios.get('/staff/' + id)
    },
    update(id, data) {
        return Axios.put('/staff/' + id, data)
    },
    delete(id) {
        return Axios.delete('/staff/' + id)
    },
}

export default user;
