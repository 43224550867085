import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import Sidebar from '../../components/Sidebar'
import { Link } from "react-router-dom";
import DataTable from "../../components/DataTable";
import resources from "../../services";
import config from '../../constants';
import Icon from '../../ui/Icon';

export default function ShopPage() {
    return (<div>
        <Header />
        <div className="container-fluid">
            <div className="row">
                <Sidebar />
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                    <div>
                        <h3>Compganies</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item active" aria-current="page">Compganies</li>
                                </ol>
                            </nav>
                            <Link className="btn btn-app-default " to="/compagnie/create"><Icon name="add" color="white" /> New</Link>
                        </div>
                    </div>

                    <div className="col mt-5">
                        <DataTable column={[
                            { label: "Name", field: "name" },
                            { label: "Note", field: "note" },
                        ]}
                            element={"compagnie"}
                            resource={resources.vitrine}
                            action={() => { }}
                            update
                            deleteItem />
                    </div>
                </main>
            </div>
        </div>

    </div>)
}


