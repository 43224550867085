import Axios from "../Axios";

const bin = {
    create(data) {
        return Axios.post('/bin', data)
    },
    read(limit = 10, page = 1) {
        return Axios.get('/bin?&limit=' + limit + "&page=" + page)
    },
    readOne(id) {
        return Axios.get('/bin/' + id)
    },
    update(id, data) {
        return Axios.put('/bin/' + id, data)
    },
    delete(id) {
        return Axios.delete('/bin/' + id)
    },
}

export default bin;
