import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import LoginPage from './pages/login/LoginPage';
import ArticlePage from './pages/Article/ArticlePage';
import Dashboard from './pages/Dashboard/Dashboard';
import CreateArticle from './pages/Article/CreateArticle';
import EditArticle from './pages/Article/EditArticle';
import ShopPage from './pages/Shop/ShopPage';
import CreateShop from './pages/Shop/CreateShop';
import EditShop from './pages/Shop/EditShop';
import CustomerPage from './pages/Customer/CustomerPage';
import CommandPage from './pages/Command/CommandPage';
import ReportPage from './pages/Report';
import CategoryPage from './pages/Category/CategoryPage';
import CreateCategory from './pages/Category/CreateCategory';
import EditCategory from './pages/Category/EditCategory';
import TagPage from './pages/Tag/TagPage';
import CreateTag from './pages/Tag/CreateTag';
import EditTag from './pages/Tag/EditTag';
import CreateVaccination from './pages/Command/CreateVaccination';
import EditVaccination from './pages/Command/EditVaccination';
import ProvisionPage from './pages/Provision/ProvisionPage';
import CreateProvision from './pages/Provision/CreateProvision';
import MouvementPage from './pages/Article/MouvementPage';
import StoragePage from './pages/Storage/StoragePage';
import CreateStorage from './pages/Storage/CreateStorage';
import EditStorage from './pages/Storage/EditStorage';
import BinPage from './pages/Bin/BinPage';
import CreateBin from './pages/Bin/CreateBin';
import EditBin from './pages/Bin/EditBin';
import CreateUser from './pages/Customer/CreateUser';
import EditUser from './pages/Customer/EditUser';
import NewArticle from './pages/Mobile/NewArticle';
import ArticleViewPage from './pages/Article/ArticleViewPage';


function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LoginPage} />
        {/*Read*/}
        {/* <Route exact path="/dashboard" component={Dashboard} /> */}
        <Route exact path="/articles" component={ArticlePage} />
        <Route exact path="/compagnies" component={ShopPage} />
        <Route exact path="/users" component={CustomerPage} />
        {/* <Route exact path="/commands" component={CommandPage} /> */}
        <Route exact path="/reports" component={ReportPage} />
        {/* <Route exact path="/provisions" component={ProvisionPage} /> */}
        <Route exact path="/setting/categories" component={CategoryPage} />
        <Route exact path="/setting/tags" component={TagPage} />
        <Route exact path="/setting/storages" component={StoragePage} />
        <Route exact path="/setting/bins" component={BinPage} />

        {/*Create*/}
        <Route exact path="/article/create" component={CreateArticle} />
        <Route exact path="/user/create" component={CreateUser} />
        <Route exact path="/compagnie/create" component={CreateShop} />
        {/* <Route exact path="/vaccination/create" component={CreateVaccination} />
        
        <Route exact path="/provision/create" component={CreateProvision} /> */}
        <Route exact path="/setting/categories/create" component={CreateCategory} />
        <Route exact path="/setting/tags/create" component={CreateTag} />
        <Route exact path="/setting/storage/create" component={CreateStorage} />
        <Route exact path="/setting/bins/create" component={CreateBin} />

        {/*Edit*/}
        <Route exact path="/article/edit/:id" component={EditArticle} />
        <Route exact path="/user/edit/:id" component={EditUser} />
        <Route exact path="/compagnie/edit/:id" component={EditShop} />
        {/* <Route exact path="/vaccination/edit/:id" component={EditVaccination} />
        */}
        <Route exact path="/setting/categories/edit/:id" component={EditCategory} />
        <Route exact path="/setting/tags/edit/:id" component={EditTag} />
        <Route exact path="/setting/storages/edit/:id" component={EditStorage} />
        <Route exact path="/setting/bins/edit/:id" component={EditBin} />

        {/*Mobile*/}
        <Route exact path="/mobile/article/create" component={NewArticle} />

        {/* View */}
        <Route exact path="/article/view/:id" component={ArticleViewPage} />


      </Switch>
    </Router>
  );
}

export default App;
