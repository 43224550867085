import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import Icon from '../ui/Icon';

function Sidebar() {
    return (<nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block btn-app-default sidebar collapse">
        <div className="position-sticky pt-3 mb-3">
            <ul className="nav flex-column ">
                {/* <li className="nav-item ">
                    <Link className="nav-link " to="/dashboard">
                        <div className="d-flex text-white">
                            <Icon name="dashboard" color="white" />
                            <p className="ms-3 fw-bolder">Dashboard</p>
                        </div>
                    </Link>
                </li> */}

                <li className="nav-item">
                    <Link className="nav-link" to="/articles">
                        <div className="d-flex text-white">
                            <Icon name="star" color="white" />

                            <p className="ms-3 fw-bolder">Articles</p>
                        </div>
                    </Link>
                </li>
                <li className="nav-item ">
                    <Link className="nav-link" to="/compagnies">
                        <div className="d-flex text-white">
                            <Icon name="shop" color="white" />
                            <p className="ms-3 fw-bolder">Company</p>
                        </div>
                    </Link>
                </li>

                {/* <li className="nav-item">
                    <Link className="nav-link" to="/commands">
                        <div className="d-flex text-white">
                            <Icon name="receipt" color="white" />
                            <p className="ms-3 fw-bolder">Vaccinations</p>
                        </div>
                    </Link>
                </li>

                <li className="nav-item ">
                    <Link className="nav-link" to="/shops">
                        <div className="d-flex text-white">
                            <Icon name="shop" color="white" />
                            <p className="ms-3 fw-bolder">Batiments</p>
                        </div>
                    </Link>
                </li> */}

                {/* <li className="nav-item ">
                    <Link className="nav-link" to="/provisions">
                        <div className="d-flex text-white">
                            <Icon name="food" color="white" />
                            <p className="ms-3 fw-bolder">Provisions</p>
                        </div>
                    </Link>
                </li>


                <div className="mt-3" /> */}

                <li className="nav-item ">
                    <Link className="nav-link" to="/users">
                        <div className="d-flex text-white">
                            <Icon name="people" color="white" />
                            <p className="ms-3 fw-bolder">Users</p>
                        </div>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className="nav-link" to="/reports">
                        <div className="d-flex text-white">
                            <Icon name="spreadsheet" color="white" />
                            <p className="ms-3 fw-bolder">Reports</p>
                        </div>
                    </Link>
                </li>

                <li class="mb-1">
                    <button type="button" class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#account-collapse" aria-expanded="false">
                        <div className="d-flex text-white">
                            <Icon name="gear" color="white" />
                            <p className="ms-3 fw-bolder">Settings</p>
                        </div>
                    </button>
                    <div class="collapse" id="account-collapse">
                        <ul class="btn-toggle-nav list-unstyled fw-normal pb-1  ms-3">
                            <li className="nav-item ">
                                <Link className="nav-link text-white" to="/setting/categories">
                                    Material type
                                </Link>
                            </li>
                            <li className="nav-item ">
                                <Link className="nav-link text-white" to="/setting/tags">
                                    Material sub-type
                                </Link>
                            </li>
                            <li className="nav-item ">
                                <Link className="nav-link text-white" to="/setting/storages">
                                    Material storage location
                                </Link>
                            </li>

                            <li className="nav-item ">
                                <Link className="nav-link text-white" to="/setting/bins">
                                    Material bin location
                                </Link>
                            </li>

                        </ul>
                    </div>
                </li>



            </ul>

        </div>
    </nav>)
}

export default Sidebar
