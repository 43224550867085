const config = {
    server: {
        localhost: "http://picco-api.bfmincorp.com",
        web: "http://picco-api.bfmincorp.com"
    },
    app: {
        name: "PICCO"
    },
    theme: {
        color: {
            default: "#000"
        }
    }
}
export default config
