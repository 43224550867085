import Axios from "../Axios";

const report = {
    read(data) {
        return Axios.post("/report/article", data);
    },
    stock(from = "", to = "") {
        return Axios.get("/rapports?zero=true&from=" + from + "&to=" + to + "");
    },
    bestSeller(from = "", to = "") {
        return Axios.get("/rapports?sell=true&from=" + from + "&to=" + to + "");
    },

}

export default report;
