import Axios from "../Axios";

const storage = {
    create(data) {
        return Axios.post('/storage', data)
    },
    read(limit = 10, page = 1) {
        return Axios.get('/storage?&limit=' + limit + "&page=" + page)
    },
    readOne(id) {
        return Axios.get('/storage/' + id)
    },
    update(id, data) {
        return Axios.put('/storage/' + id, data)
    },
    delete(id) {
        return Axios.delete('/storage/' + id)
    },
}

export default storage;
